import React from "react";

import seta from '../../../assets/images/seta.png';

import './styles.css';

const FooterBanner: React.FC = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <h2>"Não existem pequenos passos em</h2>
        <h2>GRANDES negócios."</h2>
        <strong>FALE CONOSCO</strong>
        <div className="seta">
          <img src={seta} alt="seta_para_baixo"/>
        </div>
      </div>
    </div>
  );
}

export default FooterBanner;
