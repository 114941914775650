import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Main from '../../components/PageComponents/Main';
import Footer from '../../components/PageComponents/Footer';
import FooterBanner from '../../components/PageComponents/FooterBanner';

import './styles.css';

function Landing() {

  const pageName = 'Página principal'
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView('/', pageName);
  }, []);

  return (
    <div id="landing-page" className="container">
      <Main />
      <FooterBanner />
      <Footer pageName={pageName}/>
    </div>
  );
}

export default Landing;
