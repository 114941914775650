import React from "react";
import { Link } from 'react-router-dom';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import logo from '../../../assets/images/logos/logo_com_texto.png';

import './styles.css';

const Main: React.FC = () => {

  const pageName = 'Página principal';
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  return (
    <div className="main">
      <div className="main-content">
        <div className="logo">
          <img src={logo} alt="logo"/>
        </div>          
        <div className="text-block">
          <h1>O CONTATO CERTO PARA</h1>
          <h1>O SEU NEGÓCIO.</h1>
          <div className="description">
            <strong>Excelência.</strong>
            <strong>Estratégia.</strong>
            <strong>Inovação.</strong>
            <strong>Valores Concretos.</strong>
          </div>         
        </div>
      </div>
    </div>
  );
}

export default Main;
