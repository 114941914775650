import { Link } from 'react-router-dom';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import emailIcon from '../../../assets/images/icons/email.png';
import instagramIcon from '../../../assets/images/icons/instagram.png';
import whatsappIcon from '../../../assets/images/icons/whatsapp.png';
import youtubeIcon from '../../../assets/images/icons/youtube.png';
import logo from '../../../assets/images/logos/logo.png';

import './styles.css';

interface Props {  
  pageName: string, 
}
type props = Props

const Footer = ({ pageName } : props) => {
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className='contacts'>          
          <div id="logo" className='contact'>
            <Link to='/' >
              <img src={logo} alt="home page"/>
            </Link>
          </div>
          <div id="contato" className='contact'>
            <strong>CONTATO</strong>
            <p>como nos encontrar</p>
          </div>
          <div className='contact'>
            <Link to='/fale-conosco' style={{textDecoration: 'none'}}>
              <img src={emailIcon} alt='icone_email'/>
            </Link>
            <strong>Email</strong>   
          </div>
          <div className='contact'>
            <a href="https://wa.me/5511992186188" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar whatsapp', 'Visitar whatsapp ')}>
              <img src={whatsappIcon} alt='icone_whatsapp'/>
            </a>
            <strong>Whatsapp</strong>       
          </div>
          <div className='contact'>
            <a href='https://www.instagram.com/truenumbers_/' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar instagram', 'Visitar instagram ')}>
              <img src={instagramIcon} alt='icone_instagram'/>
            </a>
            <strong>Instagram</strong>
          </div>
          <div className='contact'>
            <a href='https://www.youtube.com/@_TRUENUMBERS' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar youtube', 'Visitar youtube')}>
              <img src={youtubeIcon} alt='icone_youtube'/>
            </a>
            <strong>Youtube</strong>
          </div>
        </div>
        <strong>
          © 2023 | Contatos & Negócios - Todos os Direitos Reservados
        </strong>        
      </div>
    </footer>
  );
}

export default Footer;
