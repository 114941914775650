import axios from "axios";

export const sendEmailContactMe = async (
  senderName: string,
  senderEmail: string,
  subject: string,
  message: string
) => {
  try {
    await axios({
      method: 'POST',
      url: 'https://escola-de-numerologia-backend-k4ug74lpbq-uc.a.run.app/form/contactsAndBusiness/contact-me/send',
      data: {
        senderName,
        senderEmail,
        subject,
        message,
      },
    });
    return;
  } catch (error) {    
    return;
  }
};
