import React, { useRef, useState, useEffect } from "react";
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { TextInput, TextArea } from '../../components/Form';

import Footer from "../../components/PageComponents/Footer";

import { sendEmailContactMe } from '../../services/api';

import logo from '../../assets/images/logos/form_logo.png';

import './styles.css';

const textAreaStyle: React.CSSProperties = {
  width: '100%',
  height: '15rem',
  borderStyle: 'solid',
  background: '#FFFFFF',
  padding: '1rem',
  borderRadius: '0.3rem',
  borderColor: '#bebebe',
  color: '#656565'
}
const inputStyle: React.CSSProperties = {
  width: '100%',
  height: '4rem',  
  background: '#FFFFFF',
  marginBottom: '1.5rem',
  borderStyle: 'solid',
  borderColor: '#bebebe',
  borderRadius: '0.3rem',
  padding: '1rem',
  borderWidth: 'thin',
  color: '#656565'
}
interface FormData {
  name: string
  email: string
  subject: string
  message: string,
}

const ContactMe = () => {
  const formRef = useRef<FormHandles>(null);
  
  const [loader, setLoader] = useState(false);

  const pageName = 'Formulario Fale Conosco'
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView('/fale-conosco', pageName);
  }, []);

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Seu nome é obrigatório'),          
        email: Yup.string()
          .email('Digite um email válido')
          .required('Campo email é obrigatório'),
        subject: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Campo assunto é obrigatório'),
        message: Yup.string().min(3,'Mínimo de 3 caracteres').required('Campo mensagem é obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });      
      await sendEmailContactMe(data.name, data.email, data.subject, data.message);  
      formRef.current?.setErrors({});
      reset();
      setLoader(false);     
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }
      setLoader(false);      
    }   
  };  

  return (
    <div id="contact-me-page" className="container">
      <div className="contact-me">
        <div className="contact-me-content">
          {
            loader &&
            <div id="loader">
              <Loader type="spinner-circle" bgColor={"#656565"} color={'#FFFFFF'} size={100} />
            </div> 
          }                 
          <div className="contact-me-main">                       
            <div className="form-box">
              <div className="contact-box">
                <strong>FALE CONOSCO</strong>
              </div> 
              <Form ref={formRef} onSubmit={handleSubmit}>
                <p>Nome</p>
                <TextInput name="name" placeholder="Seu nome" type='text' styles={inputStyle} />
                <p>Email</p>
                <TextInput name="email" placeholder="Seu email" type='email' styles={inputStyle}/>
                <p>Assunto</p>
                <TextInput name="subject" placeholder="Assunto" styles={inputStyle}/>
                <p>Mensagem</p>
                <TextArea name="message" placeholder="Sua mensagem" maxLength={600} styles={textAreaStyle} />
                <div className="button-box">
                  <button type="submit">ENVIAR</button>
                </div>                
              </Form>
              <img src={logo} alt="logo"/>
            </div> 
          </div>                   
        </div>
      </div>
      <Footer pageName={pageName} />
    </div>
  );
}

export default ContactMe;
